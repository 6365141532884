//  TODO: split this file according to respoinsibility

import React from 'react'
import get from 'lodash/get'
import parsePhoneNumber from 'libphonenumber-js'
import { email } from 'react-admin'

export const displayLongText = (text, limit = 40) => {
  if (!text || limit > text.length) {
    return text
  }

  return text.substring(0, limit) + '...'
}

export const capitalizeFirstLetter = (str) => {
  if (typeof str !== 'string') {
    return ''
  }

  return `${str[0].toUpperCase()}${str.substring(1)}`
}

export const lowercaseFirstLetter = (str) => {
  if (typeof str !== 'string') {
    return ''
  }

  return str && str[0] ? `${str[0].toLowerCase()}${str.substring(1)}` : ''
}

export const formatDateTime = (
  datetime,
  locales = 'en',
  options = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }
) => {
  const date = typeof datetime === 'string' ? new Date(datetime) : datetime
  const formatter = new Intl.DateTimeFormat(locales, options)

  return formatter.format(date)
}

export const formatDate = (date) => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    day: 'numeric',
    month: 'short',
  })
}

export const formatDateForAPI = (datetime) => {
  const date = new Date(datetime).toISOString()

  return date.split('T')[0]
}

export const formatDateTimeForAPI = (datetime) => {
  let [date, time] = new Date(datetime).toISOString().split('T')
  time = time.split('.')[0]

  return `${date} ${time}`
}

export const generatePassword = () => {
  return Math.random().toString(36).slice(-8)
}

export const raElementGenerateKey = (children) => {
  let key = generatePassword()

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      return
    }

    key = get(child.props, 'source', key)
  })

  return key
}

export const getNumberFromStringId = (stringId) => {
  if (!stringId) return
  return typeof stringId === 'string'
    ? Number(stringId.replace(/\D*/g, ''))
    : Number(stringId)
}

export const isBeforeToday = (date) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  return date < today
}

export const formatText = (str) => {
  return capitalizeFirstLetter(str.replace(/_/g, ' '))
}

export const formatPrice = (val = 0, currency) => {
  if (isNaN(val)) {
    val = 0
  }
  const options = { style: 'currency', currency: currency || 'EUR' }
  return new Intl.NumberFormat('nl-NL', options).format(val)
}

const parsePhoneMemo = {}

export const parsePhoneMemoized = (number = '') => {
  if (!parsePhoneMemo[number]) {
    const result = parsePhoneNumber(number, { extract: false })
    parsePhoneMemo[number] = result
  }
  return parsePhoneMemo[number]
}
export const isPhoneNumber = (text) => !!parsePhoneMemoized(text)?.isValid()

export const startAndEnd = (str) => {
  if (str.length > 20) {
    return str.substr(0, 15) + '...' + str.substr(str.length - 5, str.length)
  }
  return str
}

export const camelCaseToWords = (str) => {
  return str
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/([A-Z][a-z])/g, ' $1')
    .replace(/(\d+)/g, ' $1')
    .toLowerCase()
}

export const snakeCaseToWords = (str) => {
  return camelCaseToWords(str.split('_').join(' '))
}

export const pluralize = (count, noun, suffix = 's') =>
  noun + (count === 1 ? '' : suffix)

export const formatDateForChargebee = (date) =>
  new Date(date).toISOString().slice(0, 10)

export const getCheckoutBaseUrl = (countryCode) => {
  const storeMap = {
    'AT': 'de-at',
    'AU': 'en-au',
    'BEFR': 'fr-be',
    'BENL': 'nl-be',
    'CHDE': 'de-ch',
    'CHFR': 'fr-ch',
    'DE': 'de-de',
    'DK': 'en-dk',
    'ES': 'es-es',
    'FR': 'fr-fr',
    'IT': 'it-it',
    'INT': 'en-glob',
    'NL': 'nl-nl',
    'NO': 'nb-no',
    'PL': 'pl-pl',
    'PT': 'pt-pt',
    'SE': 'sv-se',
    'UK': 'en-gb',
    'US': 'en-us',
  }

  const locale = storeMap[countryCode];
  const isProduction = window.location.hostname === 'admin.fitformedev.nl'
  const stageCheckout = `https://staging.fitforme.dev/${locale}/checkout`
  const prodCheckout = `https://fitforme.com/${locale}/checkout`

  return isProduction ? prodCheckout : stageCheckout
}

export const isEmail = (string) => {
  const raEmailValidator = email('email is not valid')
  const isValidEmail = !raEmailValidator(string)
  return isValidEmail
}

export const getProductVariationTitle = (productVariation) => {
  if (!productVariation) return ''
  const { interval, propertyValue, product } = productVariation
  const parts = []
  interval?.name && parts.push(interval.name)
  product?.name && parts.push(product.name)
  propertyValue?.name && parts.push(propertyValue.name)

  return parts.join(' - ')
}
