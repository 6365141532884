export default {
  midlayer: {
    uri: {
      'admin.fitformedev.nl': process.env.REACT_APP_MIDLAYER_ENTRYPOINT,
      'admin-tst.fitformedev.nl': process.env.REACT_APP_MIDLAYER_ENTRYPOINT_TST,
      'admin-sta.fitformedev.nl': process.env.REACT_APP_MIDLAYER_ENTRYPOINT_STA,
      'admin.ffm.test': process.env.REACT_APP_MIDLAYER_ENTRYPOINT_LOCAL,
      localhost: process.env.REACT_APP_MIDLAYER_ENTRYPOINT_LOCAL,
      'admin-local.fitformedev.nl': process.env.REACT_APP_MIDLAYER_ENTRYPOINT_LOCAL,
      // 'admin-local.fitformedev.nl': process.env.REACT_APP_MIDLAYER_ENTRYPOINT_STA,
    },
  },
}
